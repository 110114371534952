import Question, { QuestionDto } from "./Question";

export interface SectionDto {
    title: string
    questions: QuestionDto[],
    instructions: string
}

export default class Section
{
    title: string;
    questions: Question[];
    total: number;
    instructions: string

    constructor(
        {title, questions, instructions }: SectionDto =
            {
                title: "",
                questions: [],
                instructions: ""
            }
    )
    {
        this.title = title;
        this.questions = questions.map(question => new Question(question));    
        this.total = 0;
        this.instructions = instructions;
    }
}

import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import SessionHome from '../views/SessionHome.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: SessionHome
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Login.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/new/session',
    name: 'NewSession',
    component: () => import(/* webpackChunkName: "admin" */ '../views/NewSession.vue')
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: () => import(/* webpackChunkName: "about" */ '../views/Questionnaire.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

import Section, { SectionDto } from "./Section";


export interface QuestionnaireDto {
    id: string,
    title: string,
    sections: SectionDto[],
    current_section_idx: number,
    current_question_idx: number,
    valueGroups: { [group: string]: number},
    status: "" | "started" | 'complete'
}

export default class Questionnaire
{
    id: string;
    title: string;  
    sections: Section[];
    current_section_idx: number;
    current_question_idx: number;
    valueGroups: { [group: string]: number};
    status: "" | "started" | 'complete';

    constructor(
        {id ,title, sections, current_section_idx, current_question_idx, valueGroups, status}: QuestionnaireDto =
            {
                id: "",
                title: "",
                sections: [],
                current_section_idx: 0,
                current_question_idx: 0,
                valueGroups: {},
                status: ""
            }
    )
    {
        this.id = id;
        this.title = title;
        this.sections = sections.map(section => new Section(section));    
        this.current_section_idx = current_section_idx;
        this.current_question_idx = current_question_idx;
        this.valueGroups = valueGroups;
        this.status = status;  
    }
}

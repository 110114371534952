// Import the functions you need from the SDKs you need
import { stringify } from "@firebase/util";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, query, where, getDocs, doc, getDoc, setDoc, addDoc, updateDoc, orderBy, limit } from 'firebase/firestore/lite'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDy3irZ_H_KuDymNFDglg5BhqgDmC0ZuqE",
  authDomain: "psychassessments-c395f.firebaseapp.com",
  projectId: "psychassessments-c395f",
  storageBucket: "psychassessments-c395f.appspot.com",
  messagingSenderId: "95821116745",
  appId: "1:95821116745:web:ceff0979c06938e6fd9698"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const questionnairesCol = collection(db, 'questionnaires');
const sessionsCol = collection(db, 'sessions');

export async function getQuestionnaireForId(id: string) {
    const docRef = doc(db, "questionnaires", id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
}

export async function getQuestionnaires() {
    const docSnap = await getDocs(questionnairesCol);
    const sessions = docSnap.docs.map(doc => { 
    return doc;
  });
  
  return sessions;
}

export async function getLastSession() {

  const q = query(sessionsCol, orderBy("created_date", "desc"), limit(1));

  const sessionsSnapshot = await getDocs(q);
  const sessions = sessionsSnapshot.docs.map(doc => doc.data());

  if (sessions.length > 0)
      return sessions[0];

  return null;
}  

export async function getAllSessions() {

  const q = query(sessionsCol, orderBy("created_date", "desc"));

  const sessionsSnapshot = await getDocs(q);
  const sessions = sessionsSnapshot.docs.map(doc => doc.data());

  return sessions;
}  

export async function getSessionForCode(code: string) {
    const q = query(sessionsCol, where("access_code", "==", code));

    const sessionsSnapshot = await getDocs(q);

    const sessions = sessionsSnapshot.docs.map(doc => {
      return doc;
    });

    if (sessions.length > 0)
        return sessions[0];

    return null;
}  

export async function createSession(name: string, questionnaire_id: string, id: number, access_code: string) {

  const docRef = await addDoc(sessionsCol, {
    id: id,
    user_name: name,
    questionnaire_id: questionnaire_id,
    access_code: access_code,  
    created_date: (new Date()).getTime()/1000
  });

  return docRef;
}

export async function updateSession(session_id: string, valueGroups: string, questionnaireData: string) {

  const docRef = await updateDoc(doc(db, 'sessions', session_id), {
    valueGroups: valueGroups,
    status: 'completed',
    questionnaireData: questionnaireData
  });

  return docRef;
}
export interface AnswerDto {
    idx: number
    answer: string
    value: number
}

export default class Answer
{
    idx: number;
    answer: string;
    value: number;

    constructor(
        {idx, answer, value}: AnswerDto =
            {
                idx: 0,
                answer: "",
                value: 0,
            }
    )
    {
        this.idx = idx;
        this.answer = answer;
        this.value   = value;

    }
}

import Answer, { AnswerDto } from "./Answer";

export interface QuestionDto {
    idx: number,
    question: string
    answers: AnswerDto[],
    value_group: string,
    selectedAnswer: AnswerDto | null
}

export default class Question
{
    idx: number;
    question: string;
    answers: Answer[];
    selectedAnswer?: Answer;
    value_group: string;

    constructor(
        {idx, question, answers, value_group, selectedAnswer}: QuestionDto =
            {
                idx: 0,
                question: "",
                answers: [],
                value_group: "",
                selectedAnswer: null
            }
    )
    {
        this.idx = idx;
        this.question = question;
        this.value_group = value_group;
        this.answers = answers.map(answer => new Answer(answer));    
        
        if (selectedAnswer)
            this.selectedAnswer = this.answers[selectedAnswer.idx];

    }
}

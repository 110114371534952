import Section, { SectionDto } from "./Section";

export interface QuestionnaireSessionDto {
    id: string,
    user_name: string,
    questionnaire_id: string,
    created_date: number,
    access_code: string,
    valueGroups: string | null,
    questionnaireData: string
}

export default class QuestionnaireSession
{
    id: string;
    user_name: string;  
    questionnaire_id: string;
    created_date: number;
    created_data_object: Date;
    access_code: string;
    valueGroups: [] | null = null;
    questionnaireData: string;

    constructor(
        {id, user_name, questionnaire_id, created_date, access_code, valueGroups, questionnaireData}: QuestionnaireSessionDto =
            {
                id: "",
                user_name: "",
                questionnaire_id: "",
                created_date: 0,
                access_code: "",
                valueGroups: null,
                questionnaireData: ""
            }
    )
    {
        this.id = id;
        this.user_name = user_name;
        this.questionnaire_id = questionnaire_id;
        this.created_date = created_date;
        this.access_code = access_code;

        this.created_data_object = new Date(this.created_date*1000);

        if (valueGroups)
            this.valueGroups = JSON.parse(valueGroups);

        this.questionnaireData = questionnaireData;    
    }
}

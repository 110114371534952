
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import Questionnaire from '../models/Questionnaire'; 
import QuestionnaireSession from '../models/QuestionnaireSession';
import { getQuestionnaireForId, getSessionForCode, getLastSession } from '@/firebase';

export default defineComponent({
  name: 'Home',
  components: {
  },
  setup (props, context)  
  {
    const warning = ref<string>();
    const loading = ref<boolean>(false);
    const sessionCode = ref<string>('');
    const beginButton = ref<HTMLButtonElement>();
    const sessionCodeInput = ref<HTMLInputElement>();

    const router = useRouter();

    const beginClicked = () => {
        
        warning.value = undefined;
        if (sessionCode.value.length != 4)
        {
          loading.value = false;
          warning.value = "Please enter a valid code."
          return;
        }
        
        loading.value = true;
        if (sessionCodeInput.value)
          sessionCodeInput.value.disabled = true;

        getSessionForCode(sessionCode.value).then ((sessionData) => {

          if (sessionData)
          {
            
            if (sessionData.data().status == 'completed')
            {
              loading.value = false;
              warning.value = "The assessment linked with this code has already been completed.";
              if (sessionCodeInput.value)
                sessionCodeInput.value.disabled = false;
            }
            else
            {
              let session = new QuestionnaireSession({ 'id' : sessionData.id, 'user_name' : sessionData.data().user_name, 'questionnaire_id' : sessionData.data().questionnaire_id, 'created_date' : sessionData.data().created_date.seconds, 'access_code' : sessionData.data().access_code, 'valueGroups' : "", 'questionnaireData' : "" });
              localStorage.session = JSON.stringify(session);

              getQuestionnaireForId(session.questionnaire_id).then( (data) => {

                  if (data)
                  {
                    let questionnaire = new Questionnaire({ 'id' : session.questionnaire_id, 'title' : data.title, sections: JSON.parse(data.sections), current_section_idx: 0, current_question_idx: 0, valueGroups: {}, status: "" });
                    localStorage.questionnaire = JSON.stringify(questionnaire);

                    router.push({ name: 'Assessment' });
                  }
              });
            }
          }
          else
          {
            loading.value = false;
            warning.value = "Please ensure you entered the correct code.";

            if (sessionCodeInput.value)
              sessionCodeInput.value.disabled = false;
          }
        });
    };

   

    return { sessionCode, warning, loading, beginButton, sessionCodeInput, beginClicked }
  }
});
